// Сниппет
(function(
  o,d,n,k,s,a
) {
  o[k] || (o[k] = { init: (...opts) => {o[k].opts = opts} })
  a = d.createElement(n)
  a.async = true

  a.onload = a.onerror = () => {
    if (!a.loaded) {
      a.loaded = 1
      o[k] && o[k].create.apply(null, o[k].opts)
    }
  }

  a.onreadystatechange = function () {
    if (this.readyState == 'complete' || this.readyState == 'loaded') {
      setTimeout(function () {
        a.onload()
      }, 0)
    }
  }

  d.body.appendChild(a)
  a.src = `//${process.env.SRC_DOMAIN_NAME}/WidgetApp/WidgetApp.${process.env.SHORT_COMMIT}.js`

})(
  window,
  document,
  'script',
  'OKWIDGET'
)